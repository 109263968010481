<template>
    <div class="container padding-container">
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :span="9" >
                    <span class="list-title">测评报告</span>
                </el-col>
                <el-col :span="7" >
                    <el-input class="circular-bead-input" placeholder="请输入被邀请人姓名" prefix-icon="el-icon-search" title="按回车键搜索" clearable
                        @keyup.enter.native="pageChange(1)" @clear="pageChange(1)" v-model='search.baby_name' >
                    </el-input>
                </el-col>
                <el-col :span="7">
                    <el-input class="circular-bead-input" placeholder="请输入被邀请人联系方式" prefix-icon="el-icon-search" title="按回车键搜索" clearable
                        @keyup.enter.native="pageChange(1)" @clear="pageChange(1)" v-model.number='search.contacts_phone' >
                    </el-input>
                </el-col>
            </el-row>
            <div class="table-container">
                <el-table :data="recordList"  tooltip-effect="dark" style="width: 100%" size="medium"
                    @selection-change="handleSelectionChange"  :row-key="getRowKey"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">

                    <el-table-column type="selection" width="60" :reserve-selection="true" align="center"></el-table-column>
                    <el-table-column prop="baby_name" label="宝宝姓名" min-width="100" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div class="fz-bold hover-text-colourful" @click="routerChange(1,scope.row)">
                                {{scope.row.profile.name}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="baby_gender" label="宝宝性别" min-width="100" align="center">
                        <template slot-scope="scope">
                            {{scope.row.profile.gender | genderFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="user_phone" label="被邀请人姓名" min-width="150" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user_name}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="user_phone" label="被邀请人联系方式" min-width="150" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user_phone}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="130" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="icon-btn" @click="routerChange(1,scope.row)">
                                <el-tooltip placement="top" content="详情">
                                    <i class="iconfont icon-check" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleDeleteRecord(scope.row.id)">
                                <el-tooltip placement="top" content="删除">
                                    <i class="iconfont icon-delete" />
                                </el-tooltip>
                            </el-button>
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container with-multiple">
                <div class="left">
                    <!-- <div class="btn-area">
                        <el-button type="primary" icon="iconfont icon-delete" round plain :disabled="!multipleSelection.length"
                            @click="handleDeleteSelect">批量删除</el-button>
                    </div>
                    <div class="selected-sum">
                        <span>已选中 {{multipleSelection.length || 0}} 条</span>
                    </div> -->
                </div>

                <el-pagination class="right" layout="total, sizes, prev, pager, next" background
                    @size-change="pageSizeChange"
                    @current-change="pageChange"
                    :current-page="currentPage"
                    :page-sizes="[total, 10, 15, 20, 25]"
                    :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
        <!-- <div class="dialog-record">
            <el-dialog title="手机扫码查看" width="600px"
                :visible.sync = "dialogVisible" @close="handleRecordDialogClose">
                <div class="dialog-content">
                    <div class="recordQrCode">
                        <div id="qrcode" class="qrcode"></div>
                    </div>
                    <p>扫码在手机上查看测试结果</p>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button class="cancel" size="medium" @click="handleRecordDialogClose">取消</el-button>
                    <el-button type="primary" size="medium" @click="handleRecordDialogClose">确定</el-button>
                </div>
            </el-dialog>
        </div> -->
    </div>
</template>
<script>
import Core from '@/core';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            userType: Core.Data.getUserType(),

            multipleSelection: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            recordList: [],

            record: {
                id: 0,
                recordLink: '',
                recordQrCode: '',
            },
            search: {
                baby_name: '',
                contacts_phone: '',
            },
            dialogVisible: false,
        };
    },
    created() {
        this.getRecordList();
    },
    mounted() {},
    methods: {
        handleSelectionChange(val) { // 表格选择
            this.multipleSelection = val;
        },
        getRowKey(row) {
            return row.id;
        },
        pageChange(page) { // 页码改变
            this.currentPage = page;
            this.getRecordList();
        },
        pageSizeChange(size) { // 列表信息行数改变
            this.pageSize = size;
            this.getRecordList();
        },
        routerChange(key, q = '') { // 路由改变
            switch (key) {
                case 1: // 查看详情
                    this.$router.push({
                        path: '/operate-quotient/record-detail',
                        query: {
                            id: q.customer_id,
                            record_id: q.record_id,
                            evaluation_id: q.evaluation_data_id,
                        }
                    });
                    break;
            }
        },

        getRecordList(type) { // 获取记录列表
            Core.operationApi.Record.paging(
                this.search.baby_name,
                this.search.contacts_phone,
                this.currentPage,
                this.pageSize
            ).then((res) => {
                // console.log("getRecordList -> res", res)
                this.total = res.list.count;
                this.recordList = res.list.list
                console.log("getRecordList -> this.recordList", this.recordList)
            });
        },
        handleDeleteRecord(id) { // 删除记录
            const h = this.$createElement;
            this.$msgbox({
                title: '确定要删除吗?',
                message: h('p', null, [
                    h('span', null, '删除'),
                    h('strong', null, '测评报告'),
                    h('span', null, '会将对应的'),
                    h('strong', null, '邀请记录'),
                    h('span', null, '也删除'),
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.operationApi.Record.delete(id)
                    .then((res) => {
                        this.$message.success('删除成功!');
                        this.getRecordList();
                    })
                    .catch((err) => {
                        console.log("deleteInvite -> err", err)
                        this.$message.warning('删除失败!');
                    })
            });
        },
        handleDeleteSelect() { // 批量删除
            // this.$message.info('')
            /* const h = this.$createElement;
            this.$msgbox({
                title: '确定要批量删除吗?',
                message: h('p', null, [
                    h('span', null, '删除'),
                    h('strong', null, '测评报告'),
                    h('span', null, '会将对应的'),
                    h('strong', null, '邀请记录'),
                    h('span', null, '也删除'),
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let flag = 0
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    Core.operationApi.Record.delete(this.multipleSelection[i].id)
                        .then((res) => {
                            console.log('删除成功')
                        })
                        .catch((err) => {
                            console.log('删除失败',err)
                            flag = 1
                        })
                }
                if (flag) {
                    this.$message.warning('删除失败')
                } else {
                    this.$message.success('删除成功')
                }
                this.getRecordList();
                // this.multipleSelection = [];
            }); */
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .dialog-record {
        .dialog-content {
            @include flex(row, center,center);
            flex-wrap: wrap;
            .recordQrCode {
                width: 100%;
                margin-bottom: 20px;
                .qrcode {
                    width: 125px;
                    height: 125px;
                    margin: 0 auto;
                    padding: 8px;
                    border: 1px solid #E7ECF1;
                    img {
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>